.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    color: #ffffff;
    min-height: 100vh;
}

.blog-header {
    text-align: center;
    margin-bottom: 4rem;
    padding: 2rem;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
}

.title-container {
    margin-bottom: 2rem;
}

.title-container h1 {
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 1rem;
    background: linear-gradient(45deg, #ffd700, #ff6b6b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
}

.subtitle {
    font-size: 1.5rem;
    color: #cccccc;
    font-weight: 300;
}

.event-details {
    display: flex;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
}

.detail-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.1rem;
}

.icon {
    font-size: 1.5rem;
}

.game-section {
    margin: 4rem 0;
}

.game-intro {
    text-align: center;
    margin-bottom: 2rem;
}

.game-intro h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #ffd700;
}

.game-intro p {
    color: #cccccc;
    font-size: 1.2rem;
}

.fight-info {
    margin: 4rem auto;
    padding: 2rem;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    max-width: 800px;
}

.fighter-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.fighter {
    flex: 0 1 auto;
    padding: 1.5rem;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fighter:hover {
    transform: translateY(-5px);
}

.fighter img {
    width: 200px;
    height: 300px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 1rem;
    border: 3px solid #ffd700;
    transition: transform 0.3s ease;
}

.fighter img:hover {
    transform: scale(1.05);
}

.fighter h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #ffffff;
}

.fighter ul {
    list-style: none;
    padding: 0;
}

.fighter ul li {
    margin: 0.5rem 0;
    color: #cccccc;
}

.vs {
    font-size: 2.5rem;
    font-weight: 800;
    color: #ffd700;
}

.fighter-bio {
    margin-top: 1rem;
    padding: 1rem;
    color: #cccccc;
    font-size: 0.9rem;
    line-height: 1.4;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
    .blog-container {
        padding: 1rem;
    }

    .title-container h1 {
        font-size: 2.5rem;
    }

    .fighter-stats {
        flex-direction: column;
    }

    .fighter img {
        width: 150px;
        height: 225px;
    }
} 