.fight-game-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    background: #1a1a1a;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.arena-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: radial-gradient(circle at center, #2d2d2d 0%, #1a1a1a 100%);
    border-radius: 12px;
}

.spotlight-left, .spotlight-right {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    filter: blur(50px);
    animation: pulse 2s infinite alternate;
}

.spotlight-left {
    left: -100px;
    top: 50%;
    background: rgba(0, 100, 255, 0.1);
}

.spotlight-right {
    right: -100px;
    top: 50%;
    background: rgba(255, 0, 0, 0.1);
}

.ring-ropes {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20% 5%;
}

.rope {
    height: 2px;
    background: linear-gradient(90deg, 
        transparent 0%, 
        rgba(255, 215, 0, 0.3) 20%, 
        rgba(255, 215, 0, 0.5) 50%,
        rgba(255, 215, 0, 0.3) 80%,
        transparent 100%
    );
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
    animation: ropeGlow 2s infinite alternate;
}

.character-options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    position: relative;
    z-index: 1;
}

.character-card {
    position: relative;
    width: 280px;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    animation: float 3s infinite alternate;
}

.character-card.jake {
    animation-delay: 0s;
}

.character-card.tyson {
    animation-delay: 1.5s;
}

.character-card:hover {
    transform: scale(1.05);
}

.character-image-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
}

.hover-glow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.hover-glow.blue {
    background: radial-gradient(circle at center, rgba(0, 100, 255, 0.3), transparent);
}

.hover-glow.red {
    background: radial-gradient(circle at center, rgba(255, 0, 0, 0.3), transparent);
}

.character-card:hover .hover-glow {
    opacity: 1;
}

.character-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    margin: 0 auto;
}

.character-card:hover .character-preview {
    transform: scale(1.1);
}

.character-info {
    padding: 1rem;
    text-align: center;
    color: #fff;
}

.character-info h4 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #ffd700;
}

.stats {
    margin-top: 1rem;
}

.stat {
    color: #ccc;
    margin: 0.5rem 0;
}

.vs-container {
    position: relative;
    z-index: 2;
}

.vs-circle {
    width: 4rem;
    height: 4rem;
    background: radial-gradient(circle at center, #ffd700, #ff8c00);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse 2s infinite;
}

.vs-text {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@keyframes float {
    0% { transform: translateY(0px); }
    100% { transform: translateY(-10px); }
}

@keyframes pulse {
    0% { opacity: 0.5; transform: scale(1); }
    100% { opacity: 1; transform: scale(1.1); }
}

@keyframes ropeGlow {
    0% { opacity: 0.3; }
    100% { opacity: 0.8; }
}

.select-title {
    font-size: 2rem;
    color: #ffffff;
    text-align: center;
    margin: 1.5rem 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.select-prompt {
    text-align: center;
    color: #ffd700;
    margin-top: 1rem;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.character-card:hover .select-prompt {
    opacity: 1;
}

.game-area {
    position: relative;
    background: #1a1a1a;
}

.game-over-overlay button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

canvas {
    background: #1a1a1a;
    border-radius: 8px;
    cursor: pointer;
}

@media (max-width: 840px) {
    .fight-game-container {
        padding: 0.5rem;
        margin: 0.5rem auto;
    }
    
    canvas {
        width: 100%;
        height: auto;
        aspect-ratio: 4/3;
        image-rendering: auto;
    }

    /* Disable complex animations on mobile */
    .spotlight-left,
    .spotlight-right,
    .rope {
        display: none;
    }

    /* Simplify character card animations */
    .character-card {
        animation: none;
        transform: none;
    }

    .character-card:hover {
        transform: scale(1.02);
    }

    /* Optimize touch response */
    .game-area {
        touch-action: manipulation;
        -webkit-tap-highlight-color: transparent;
    }

    /* Reduce visual effects */
    .select-title {
        text-shadow: none;
    }

    .vs-circle {
        animation: none;
    }

    /* Simpler sliding message on mobile */
    .sliding-message {
        font-size: 16px;
        bottom: 10px;
    }
}

/* Additional mobile optimizations */
@media (max-width: 480px) {
    .fight-game-container {
        margin: 0;
        border-radius: 0;
    }

    canvas {
        border-radius: 0;
    }

    /* Further reduce animations */
    .character-card:hover {
        transform: none;
    }

    .select-prompt {
        opacity: 1;
    }
}

.character-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 1rem;
}

.character-card:last-child .character-preview {
    transform: scaleX(-1);
}

.game-canvas {
    width: 800px;
    height: 600px;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
    background: #1a1a1a;
}

.score-display {
    margin: 1.5rem 0;
    font-size: 1.5rem;
    color: #fff;
}

.final-score {
    font-size: 2rem;
    color: #ffd700;
    margin-bottom: 1rem;
}

.final-flips {
    color: #ff6b6b;
    font-size: 1.5rem;
}

.fight-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
  overflow: hidden;
}

.character-container {
  max-width: 150px;
  width: 40%;
  min-width: 100px;
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.7));
  margin: 10px;
}

.vs-text {
  font-size: clamp(24px, 5vw, 48px);
  margin: 0 10px;
}

@media (max-width: 480px) {
  .fight-container {
    gap: 10px;
  }
  
  .character-container {
    width: 35%;
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.7));
  }
  
  .vs-text {
    margin: 0 5px;
  }
}

.sliding-message-container {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    height: 40px;
    overflow: hidden;
    pointer-events: none;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.sliding-message-container.active {
    opacity: 1;
}

.sliding-message {
    position: absolute;
    color: #FFD700;
    font-size: 24px;
    font-weight: bold;
    white-space: nowrap;
    text-shadow: 
        2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000,
        -2px 2px 0 #000;
    animation: slideLeft 4s linear;
    left: 100%;
}

@keyframes slideLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-250%);
    }
}

/* Mobile adjustments */
@media (max-width: 840px) {
    .sliding-message-container {
        bottom: 8px;
    }
    .sliding-message {
        font-size: 20px;
        text-shadow: 
            1px 1px 0 #000,
            -1px -1px 0 #000,
            1px -1px 0 #000,
            -1px 1px 0 #000;
    }
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
 