.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.modal-content {
    background: radial-gradient(circle at center, #2d2d2d 0%, #1a1a1a 100%);
    padding: 2rem;
    border-radius: 15px;
    text-align: center;
    color: #fff;
    border: 2px solid rgba(255, 215, 0, 0.3);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    min-width: 300px;
}

.modal-content h3 {
    font-size: 2rem;
    color: #ffd700;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.modal-action {
    margin-top: 1.5rem;
    padding: 0.75rem 2rem;
    font-size: 1.2rem;
    background: linear-gradient(45deg, #ffd700, #ff8c00);
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.modal-action:hover {
    transform: translateY(-2px);
} 