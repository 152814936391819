body {
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    min-height: 100vh;
    overflow-x: hidden;
}

.app {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    overflow-x: hidden;
}

/* Remove white space on sides */
.blog-container {
    width: 100%;
    max-width: 1200px;
    margin: 0;
    padding: 2rem;
    background: transparent;  /* Remove background to prevent white edges */
}

/* Prevent horizontal scrolling */
* {
    box-sizing: border-box;
    max-width: 100%;
}

@media (max-width: 768px) {
    .blog-container {
        padding: 1rem;
    }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
